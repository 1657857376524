import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import EMLogo from "../components/EMLogo"
import danceGif from "../images/dance.gif"

const GiveLoveThanksPage = () => (
  <Layout 
    title="Empower + Rise: Thank you!"
    simpleFooter
  >
    <section className="black overflow-hidden relative">
      <div className="mt4 mb5 flex justify-around items-center w-100">
        <Link to="/">
          <EMLogo style={{ fill: "#dad1d2", width: "160px" }} />
        </Link>
      </div>

      <div className="mb4 w-90 f2 f-headline-ns center tc black">
        You Gave Love!
      </div>

      <div className="mb4 f1-ns f3 center tc black">OMG OMG OMG</div>

      <div className="center tc mb5">
        <img src={danceGif} />
      </div>

      <div className="f4-ns f5 center brandon-light lh-copy tc w-50-ns w-90 mb6-ns mb4">
        To show our extra special appreciation, do us one more favor... check
        your inbox!! WE have a gift of love coming your way. Just one more thing
        to remind you exactly how loveable you are.
      </div>
    </section>
  </Layout>
)

export default GiveLoveThanksPage
